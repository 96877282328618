import {convertToDate} from 'lib/dates';

export function deserializeAppAnnouncement(announcementData) {
  const dateFields = ['published_at', 'publication_end', 'created_at', 'updated_at'];
  dateFields.forEach((dateField) => {
    announcementData[dateField] = convertToDate(announcementData[dateField]);
  });

  return announcementData;
}
